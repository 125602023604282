<!-- <template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">MoneyMate</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">เกี่ยวกับเรา</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">บริการของเรา</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarPAge",
};
</script>

<style>
.navbar {
  color: #dd2c31;
}
</style> -->

<template>
  <nav class="navbar fixed-navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container mx-4">
      <router-link class="navbar-brand" to="/">MoneyMate</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="scrollToSection('home')"
              >หน้าหลัก</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="scrollToSection('AboutUs')"
              >เกี่ยวกับเรา</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="scrollToSection('form')"
              >สมัคร</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click.prevent="scrollToSection('contact')"
              >ติดต่อเรา</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarPage",
  methods: {
    scrollToSection(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    },
  },
};
</script>
<style>
a {
  color: black;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}
</style>
