import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDjYeWyXGyaAjRSr7Yhh5Uv0FY86FCyGY4",
  authDomain: "moneymatecontact-abfe1.firebaseapp.com",
  projectId: "moneymatecontact-abfe1",
  storageBucket: "moneymatecontact-abfe1.appspot.com",
  messagingSenderId: "1021377527814",
  appId: "1:1021377527814:web:8636e2e83e54e4afde0387",
  measurementId: "G-WQM41TB179"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
