<template>
  <div id="app">
    <Navbar />
    <FacebookChat />
    <Welcome id="home" />
    <AboutUs id="AboutUs" />
    <OurServices />

    <CriteriaPage />
    <getStart />
    <ContactForm id="form" />
    <FaqPage />
    <ContactUs id="ContactUs" />
    <Footer id="contact" />
  </div>
</template>

<script>
import FacebookChat from "@/components/FacebookChat.vue";
import FaqPage from "@/components/FaqPage.vue";
import getStart from "@/components/getStart.vue";
import Navbar from "./components/NavbarPage.vue";
import Welcome from "./components/WelcomePage.vue";
import AboutUs from "./components/AboutUs.vue";
import OurServices from "./components/OurServices.vue";
import ContactForm from "./components/ContactForm.vue";
import Footer from "./components/FooterPage.vue";
import CriteriaPage from "./components/CriteriaPage.vue";
import ContactUs from "./components/ContactUs.vue";

export default {
  name: "App",
  components: {
    FacebookChat,
    Navbar,
    Welcome,
    AboutUs,
    ContactForm,
    Footer,
    OurServices,
    CriteriaPage,
    ContactUs,
    FaqPage,
    getStart,
  },
};
</script>

<style>
body {
  font-family: "Sukhumvit Set", Arial, sans-serif;
  background-color: #fffdf8;
}
.bg-dark {
  background-color: #2f3131 !important;
}
.text-white {
  color: #fffdf8 !important;
}
button {
  /* background-color: #dd2c31 !important; */
}
p {
  font-size: 1.5rem;
}
h1 {
  font-size: 3.3rem;
}
</style>
