<template>
  <div class="contact">
    <div class="container py-5">
      <h2 class="text-center">ติดต่อเรา</h2>
      <div class="row text-center">
        <div class="col-md-3">
          <i class="fas fa-user fa-2x mb-3"></i>
          <h5>Name</h5>
          <p>MoneyMate</p>
        </div>
        <div class="col-md-3">
          <i class="fas fa-phone fa-2x mb-3"></i>
          <h5>Phone</h5>
          <a type="tel" href="0898882444"><p>0898882444</p></a>
        </div>
        <div class="col-md-3">
          <i class="fab fa-line fa-2x mb-3"></i>
          <h5>Line</h5>
          <a
            href="https://lin.ee/3eb3lJh"
            target="_blank"
            rel="noopener noreferrer"
            >@MoneyMateth</a
          >
        </div>
        <div class="col-md-3">
          <i class="fab fa-facebook fa-2x mb-3"></i>
          <h5>Facebook</h5>
          <a
            href="https://www.facebook.com/people/MoneyMate/100089781318310/"
            target="_blank"
            rel="noopener noreferrer"
            >facebook.com/moneymate</a
          >
        </div>
      </div>

      <!-- <div class="row text-center mt-5">
        <div class="col-md-3">
          <i class="fab fa-line fa-2x mb-3"></i>
          <h5>Line</h5>
          <p>@moneymate</p>
        </div>
        <div class="col-md-3">
          <i class="fab fa-facebook fa-2x mb-3"></i>
          <h5>Facebook</h5>
          <a
            href="https://www.facebook.com/moneymate"
            target="_blank"
            rel="noopener noreferrer"
            >facebook.com/moneymate</a
          >
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style scoped>
.container {
  color: #2f3131;
}

.fa-2x {
  color: #dd2c31;
}
.contact {
}

a {
  color: #dd2c31;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
