<template>
  <section class="faq" id="faq">
    <div class="container">
      <div class="faq-contain text-center py-5">
        <h2>คำถามที่พบบ่อย</h2>
      </div>

      <div class="row">
        <div class="q1 col-xl-12 col-s-12 mb-4 w-20">
          <div
            id="collapse"
            class="ques shadow media collapsed"
            data-toggle="collapse"
            data-target="#FirstQuestion"
            aria-expanded="false"
            aria-controls="FirstQuestion"
            v-on:click="handleQuestionbox(1)"
          >
            <div class="media-body justify-text-center">
              <span class="icon" id="icon1"> - </span>
              <span class="content">MoneyMate คืออะไร</span>
            </div>
          </div>
        </div>

        <div class="container collapse show" id="FirstQuestion">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10 ans">
              <div class="leftline"></div>
              <span>
                MoneyMate เป็นผู้ช่วยให้คำแนะนำและตัวแทนด้านรีไฟแนนซ์รถยนต์
                และสินเชื่อจำนำทะเบียนรถเพื่อค้นหาสินเชื่อที่เหมาะสมกับคุณ
              </span>
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-s-12 mb-4">
          <div
            class="ques shadow media collapsed"
            data-toggle="collapse"
            data-target="#SecondQuestion"
            aria-expanded="false"
            aria-controls="SecondQuestion"
            v-on:click="handleQuestionbox(2)"
          >
            <div class="media"></div>

            <div class="faq-textmedia-body justify-content-center">
              <span class="icon" id="icon2"> + </span>
              <span>MoneyMate เป็นสถาบันการเงินหรือไม่ ?</span>
            </div>
          </div>
        </div>

        <div class="container collapse" id="SecondQuestion">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10 ans">
              <div class="leftline"></div>
              <span
                >MoneyMate เป็นเพียงตัวแทนสินเชื่อจำนำทะเบียนรถ
                เพื่ออำนวยความสะดวกและให้คำแนะนำกับลูกค้า
                โดยทุกธุรกรรมจะเกิดขึ้นกับธนาคารที่ได้รับการรับรองเท่านั้น</span
              >
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-s-12 mb-4">
          <div
            class="ques shadow media collapsed"
            data-toggle="collapse"
            data-target="#ThirdQuestion"
            aria-expanded="false"
            aria-controls="ThirdQuestion"
            v-on:click="handleQuestionbox(3)"
          >
            <div class="media"></div>

            <div class="faq-textmedia-body justify-content-center">
              <span class="icon" id="icon3"> + </span>
              <span>ค่าใช้จ่ายในการดำเนินการ</span>
            </div>
          </div>
        </div>

        <div class="container collapse" id="ThirdQuestion">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10 ans">
              <div class="leftline"></div>
              <span
                >MoneyMate ไม่คิดค่าบริการและการดำเนินการใด ๆ
                จากลูกค้าทั้งสิ้น</span
              >
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-s-12 mb-4">
          <div
            class="ques shadow media collapsed"
            data-toggle="collapse"
            data-target="#FourthQuestion"
            aria-expanded="false"
            aria-controls="FourthQuestion"
            v-on:click="handleQuestionbox(4)"
          >
            <div class="media"></div>

            <div class="faq-textmedia-body justify-content-center">
              <span class="icon" id="icon4"> + </span>
              <span>ไม่เข้าเงื่อไข ติดแบล็คลิสต์ สมัครได้หรือไม่</span>
            </div>
          </div>
        </div>

        <div class="container collapse" id="FourthQuestion">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10 ans">
              <div class="leftline"></div>
              <span
                >สามารถติดต่อเพื่อเข้ารับคำปรึกษากับ MoneyMate ได้
                โดยทางเราจะให้คำแนะนำ และค้นหาสินเชื่อที่ใช้ให้กับคุณ</span
              >
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-s-12 mb-4">
          <div
            class="ques shadow media collapsed"
            data-toggle="collapse"
            data-target="#FifthQuestion"
            aria-expanded="false"
            aria-controls="FifthQuestion"
            v-on:click="handleQuestionbox(5)"
          >
            <div class="media"></div>

            <div class="faq-textmedia-body justify-content-center">
              <span class="icon" id="icon5"> + </span>
              <span>เมื่อทำสินเชื่อจำนำทะเบียนรถแล้ว สามารใช้รถได้ไหม</span>
            </div>
          </div>
        </div>

        <div class="container collapse" id="FifthQuestion">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10 ans">
              <div class="leftline"></div>
              <span>
                ลูกค้าสามารถใช้รถได้ปกติระหว่างผ่อนชำระสินเชื่อจำนำทะเบียนรถ</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    handleQuestionbox: function (rowid) {
      var icon = document.getElementById(`icon${rowid}`);
      icon.innerHTML = icon.innerHTML != " + " ? " + " : " - ";
    },
  },
};
</script>

<style scoped>
.faq {
  height: 100%;
  background-color: #d3d4d6;
}

.faq-contain h1 {
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-thickness: 0.3rem;
  border-radius: 1rem;
  text-decoration-color: #545454;
}

.faq-contain h2 {
  color: var(--EBlue);
}

.ques {
  background-color: #fffdf8;
  border-radius: 1rem;
  padding: 1.5rem;
}

.ques span {
  font-size: clamp(20px, 2vw, 40px);

  font-weight: 400;
  display: inline;
}

.ans {
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  column-gap: 10%;
  justify-content: flex-start;
}

.ans span {
  font-size: 1.3rem;
}

.meadia-body {
  padding: 2rem;
}

.icon {
  margin: 1rem;
  margin-right: 1rem;
}

.leftline {
  border: solid 0.2rem black;
  border-radius: 0.1rem;
  width: 0.5%;
  height: 100%;
}
@media (max-width: 800px) {
  .faq-contain {
    text-align: center;
    font-size: clamp(20px, 2vw, 50px);
  }
}
</style>
