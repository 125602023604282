<template>
  <div class="container-fluid get-start py-5">
    <h2 class="text-center mb-4 py-4">เริ่มต้นกับเราได้ง่าย ๆ</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 mb-4 text-center"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="d-flex flex-column align-items-center">
          <div class="img-container">
            <img
              :src="item.image"
              class="img-fluid img-size"
              :alt="item.caption"
            />
          </div>
          <p class="mt-2 text-center align-self-center">{{ item.caption }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "@/assets/FillForm.png";
import img2 from "@/assets/contact.png";
import img3 from "@/assets/PaperWork.png";
import img4 from "@/assets/getMoney.png";
export default {
  name: "GetStart",
  data() {
    return {
      items: [
        { image: img1, caption: "กรอกข้อมูล" },
        {
          image: img2,
          caption: "รอการติดต่อจากเจ้าหน้าที่ของเรา",
        },
        { image: img3, caption: "นัดตรวจเอกสารและสภาพรถ" },
        { image: img4, caption: "รับเงินด่วนทันใจ" },
      ],
    };
  },
};
</script>

<style scoped>
.img-container {
  height: 300px; /* Adjust this height based on your needs */
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-size {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 300px;
  max-height: 300px;
}
.get-start {
}
</style>
