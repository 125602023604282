<template>
  <div class="welcome">
    <div class="welcon container py-5">
      <div class="row">
        <div class="col-md-6 order-lg-2">
          <img
            src="../assets/Car_red.svg"
            alt="Welcome Image"
            class="img-fluid"
          />
        </div>
        <div class="col-md-6 align-self-center order-lg-1 pt-3">
          <h1 v-motion="'fadeIn'" v-motion-duration="1" v-motion-delay="0.5">
            ทำให้เรื่องเงิน ให้เป็นเรื่องง่าย
          </h1>
          <p class="mt-4">
            MoneyMate ครบ จบ ทุกเรื่องการเงิน
            เราให้บริการสินเชื่อรถส่วนบุคคลวงเงินใหญ่สูงสุด 1 ล้านบาท
            อนุมัติไวใน 1 ชั่วโมง รับเงินทันที
          </p>
          <button type="button" class="btn btn-danger align-self-center">
            <a @click.prevent="scrollToSection('form')">สมัครเลย</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomePage ",
  methods: {
    scrollToSection(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    },
  },
};
</script>
<style>
.welcome {
  margin-top: 2rem;
}
</style>
