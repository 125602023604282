<template>
  <footer class="bg-dark text-white py-3">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p>
            &copy; {{ new Date().getFullYear() }} MoneyMate. All rights
            reserved.
          </p>
        </div>
        <!-- <div class="col-md-6">
          <ul class="nav justify-content-end">
            <li class="nav-item">
              <a class="nav-link text-white" href="#home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#form">Form</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-white" href="#contact">Contact Us</a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>
