<template>
  <div class="container our-services">
    <h2 class="text-center">บริการของเรา</h2>
    <div class="row pt-3">
      <div class="col-md-6 text-center">
        <div class="service">
          <img
            class=""
            src="../assets/Car_service.png"
            alt="Service 1"
            style="min-width: 15rem"
          />
          <h2>สินเชื่อรถยนต์/กระบะ</h2>
          <div class="criteriax text-lg-left py-4"></div>
        </div>
      </div>
      <div class="col-md-6 text-center">
        <div class="service">
          <img
            class=""
            src="../assets/Motorbike_service.png"
            alt="Service 2"
            style="min-width: 9rem"
          />
          <h3>สินเชื่อรถจักรยานยนต์</h3>
        </div>
      </div>
      <!-- <div class="col-md-4 text-center">
        <div class="service">
          <img
            class="rounded-circle"
            src="https://via.placeholder.com/150"
            alt="Service 3"
          />
          <h3>รีไฟแนนซ์</h3>
          <p>
            Integer gravida justo vitae quam pharetra, ac sodales odio lacinia.
            Sed imperdiet ipsum.
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OurServices",
};
</script>

<style scoped>
.our-services {
  padding: 2rem 0;
}

.service img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1rem;
}
</style>
