<template>
  <div class="criteria">
    <div class="container py-5">
      <div class="row">
        <div class="col-md-6">
          <h2 class="">คุณสมบัติ</h2>
          <ul>
            <li><p class="">สัญชาติไทย อายุ 20 – 65 ปี</p></li>
            <li>
              <p>
                รายได้ขั้นต่ำ 8,000 บาท ต่อเดือน (กรณีเจ้าของกิจการ
                มียอดขายต่อเดือนไม่ต่ำกว่า 30,000 บาท)
              </p>
            </li>
            <li>
              <p>
                ประสบการณ์ทำงานไม่ต่ำกว่า 4 เดือน
                (กรณีเจ้าของกิจการต้องดำเนินธุรกิจไม่ต่ำกว่า 6 เดือน)
              </p>
            </li>
            <li><p>มีรถเป็นชื่อของตนเอง</p></li>
          </ul>
        </div>
        <div class="col-md-6">
          <h2 class="">เอกสารที่ต้องใช้</h2>
          <ul>
            <li><p>บัตรประชาชนตัวจริง</p></li>
            <li><p>สำเนาทะเบียนบ้าน</p></li>
            <li><p>สำเนาหน้าแรกของสมุดบัญชีธนาคาร</p></li>
            <li>
              <p>
                ทะเบียนรถเล่มจริง รถไม่ติดไฟแนนซ์ และมีชื่อผู้กู้เป็นเจ้าของ
              </p>
            </li>
          </ul>
        </div>
      </div>
      <p class="text-center">
        ***หากคุณสมบัติไม่ครบไม่ต้องกังวลไป เพียงติดต่อมา เราพร้อมให้คำปรึกษาคุณ
      </p>
    </div>
  </div>
</template>

<style>
.criteria {
  background-color: #d3d4d6;
}
</style>
