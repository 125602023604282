<template>
  <div class="AboutUs">
    <div class="container">
      <div class="text py-5">
        <h2 class="text-center p-4">เกี่ยวกับเรา</h2>
        <div class="row">
          <div class="col-md-12">
            <p>
              Money Mate เป็นผู้ช่วยให้คำแนะนำ/ปรึกษาฟรี ด้านรีไฟแนนซ์รถยนต์
              และสินเชื่อทะเบียนรถฯ
              เรารับฟังและพร้อมช่วยประสานงานกับสถาบันการเงิน
              เพื่อเพิ่มโอกาสในการได้รับวงเงินที่สูง
              และความรวดเร็วในการพิจารณาอนุมัติวงเงินสินเชื่อ อย่างมืออาชีพ
              ทั้งนี้ฯ ธุรกรรมทางการเงินทั้งหมด จะเกิดขึ้นโดยตรง
              ระหว่างลูกค้ากับสถาบันการเงินที่ได้รับการรับรองเท่านั้น
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>
<style>
.AboutUs {
  background-color: #d3d4d6;
}
</style>
