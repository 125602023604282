<template>
  <div class="container py-5">
    <h2 class="text-center">สอบถาม / สนใจ</h2>
    <form @submit.prevent="submitForm">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="name-input">ชื่อ</label>
          <input
            id="name-input"
            type="text"
            class="form-control"
            v-model="formData.name"
            required
          />
        </div>
        <div class="form-group col-md-6">
          <label for="surname-input">นามสกุล</label>
          <input
            id="surname-input"
            type="text"
            class="form-control"
            v-model="formData.surname"
            required
          />
        </div>
      </div>

      <div class="form-group">
        <label for="email-input">Email</label>
        <input
          id="email-input"
          type="email"
          class="form-control"
          :class="{ 'is-invalid': formData.email && !isValidEmail }"
          v-model="formData.email"
        />
        <div class="invalid-feedback" v-if="formData.email && !isValidEmail">
          โปรดระบุอีเมลให้ถูกต้อง
        </div>
      </div>

      <div class="form-group">
        <label for="carYear-input">ปีรถ (ค.ศ.)</label>
        <input
          id="carYear-input"
          type="number"
          min="2000"
          class="form-control"
          :class="{ 'is-invalid': formData.carYear && !validCarYear }"
          v-model="formData.carYear"
          required
        />
        <div class="invalid-feedback" v-if="formData.carYear && !validCarYear">
          รถต้องผลิตระหว่างปี 2000 and {{ new Date().getFullYear() }}.
        </div>
      </div>

      <div class="form-group">
        <label for="income-input">รายได้</label>
        <select
          id="income-input"
          class="form-control"
          v-model="formData.income"
          required
        >
          <option value="8000-11999">8,000-11,999</option>
          <option value="12000-14999">12,000-14,999</option>
          <option value="15000-19999">15,000-19,999</option>
          <option value="20000-29999">20,000-29,999</option>
          <option value="30000-39999">30,000-39,999</option>
          <option value="40000-49999">40,000-49,999</option>
          <option value="50000-up">50,000 ++</option>
          <option value="None">-</option>
        </select>
      </div>

      <div class="form-group">
        <label for="phone-input">เบอร์โทรศัพท์</label>
        <input
          id="phone-input"
          type="tel"
          class="form-control"
          :class="{ 'is-invalid': formData.phone && !isValidPhone }"
          v-model="formData.phone"
          required
        />
        <div class="invalid-feedback" v-if="formData.phone && !isValidPhone">
          กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง
        </div>
      </div>
      <div class="form-group">
        <label for="regist-input"
          >มีเล่มทะเบียนรถยนต์ ที่ผ่อนหมดแล้ว หรือไม่</label
        >
        <select
          id="regist-input"
          class="form-control"
          v-model="formData.regist"
          required
        >
          <option value="Yes">มี</option>
          <option value="No">ไม่มี</option>
        </select>
      </div>

      <div class="form-group">
        <label for="carBrand-input">ยี่ห้อรถ</label>
        <select
          id="carBrand-input"
          class="form-control"
          v-model="formData.carBrand"
          required
        >
          <option value="" disabled selected>ยี่ห้อรถยนต์</option>
          <option value="Toyota">Toyota</option>
          <option value="Honda">Honda</option>
          <option value="Isuzu">Isuzu</option>
          <option value="Mitsubishi">Mitsubishi</option>
          <option value="Nissan">Nissan</option>
          <option value="Mazda">Mazda</option>
          <option value="Ford">Ford</option>
          <option value="Chevrolet">Chevrolet</option>
          <option value="Suzuki">Suzuki</option>
          <option value="Mercedes Benz">Mercedes Benz</option>
          <option value="MG">MG</option>
          <option value="BMW">BMW</option>
          <option value="Hyundai">Hyundai</option>
          <option value="Subaru">Subaru</option>
          <option value="Mini">Mini</option>
          <option value="Kia">Kia</option>
          <option value="Audi">Audi</option>
          <option value="Lexus">Lexus</option>
          <option value="Volvo">Volvo</option>
          <option value="etc">อื่น ๆ</option>
        </select>
      </div>

      <div class="form-group" v-if="formData.carBrand === 'etc'">
        <label for="otherCarBrand-input">อื่น ๆ (โปรดระบุ)</label>
        <input
          id="otherCarBrand-input"
          type="text"
          class="form-control"
          v-model="formData.otherCarBrand"
          required
        />
      </div>

      <div class="form-group">
        <label for="province-input">จังหวัด</label>
        <select
          id="province-input"
          class="form-control"
          v-model="formData.province"
          required
        >
          <option value="">โปรดเลือกจังหวัด</option>
          <option value="Songkhla">สงขลา</option>
          <option value="Pattani">ปัตตานี</option>
          <option value="Yala">ยะลา</option>
          <option value="Narathiwat">นราธิวาส</option>
          <option value="Bangkok">กรุงเทพมหานคร</option>
          <option value="Samut Prakan">สมุทรปราการ</option>
          <option value="Nonthaburi">นนทบุรี</option>
          <option value="Pathum Thani">ปทุมธานี</option>
          <option value="Phra Nakhon Si Ayutthaya">พระนครศรีอยุธยา</option>
          <option value="Ang Thong">อ่างทอง</option>
          <option value="Lopburi">ลพบุรี</option>
          <option value="Sing Buri">สิงห์บุรี</option>
          <option value="Chai Nat">ชัยนาท</option>
          <option value="Saraburi">สระบุรี</option>
          <option value="Chonburi">ชลบุรี</option>
          <option value="Rayong">ระยอง</option>
          <option value="Chanthaburi">จันทบุรี</option>
          <option value="Trat">ตราด</option>
          <option value="Chachoengsao">ฉะเชิงเทรา</option>
          <option value="Prachinburi">ปราจีนบุรี</option>
          <option value="Nakhon Nayok">นครนายก</option>
          <option value="Sa Kaeo">สระแก้ว</option>
          <option value="Nakhon Ratchasima">นครราชสีมา</option>
          <option value="Buri Ram">บุรีรัมย์</option>
          <option value="Surin">สุรินทร์</option>
          <option value="Si Sa Ket">ศรีสะเกษ</option>
          <option value="Ubon Ratchathani">อุบลราชธานี</option>
          <option value="Yasothon">ยโสธร</option>
          <option value="Chaiyaphum">ชัยภูมิ</option>
          <option value="Amnat Charoen">อำนาจเจริญ</option>
          <option value="Bueng Kan">บึงกาฬ</option>
          <option value="Nong Bua Lamphu">หนองบัวลำภู</option>
          <option value="Khon Kaen">ขอนแก่น</option>
          <option value="Udon Thani">อุดรธานี</option>
          <option value="Loei">เลย</option>
          <option value="Nong Khai">หนองคาย</option>
          <option value="Maha Sarakham">มหาสารคาม</option>
          <option value="Roi Et">ร้อยเอ็ด</option>
          <option value="Kalasin">กาฬสินธุ์</option>
          <option value="Sakon Nakhon">สกลนคร</option>
          <option value="Nakhon Phanom">นครพนม</option>
          <option value="Mukdahan">มุกดาหาร</option>
          <option value="Chiang Mai">เชียงใหม่</option>
          <option value="Lamphun">ลำพูน</option>
          <option value="Lampang">ลำปาง</option>
          <option value="Uttaradit">อุตรดิตถ์</option>
          <option value="Phrae">แพร่</option>
          <option value="Nan">น่าน</option>
          <option value="Phayao">พะเยา</option>
          <option value="Chiang Rai">เชียงราย</option>
          <option value="Mae Hong Son">แม่ฮ่องสอน</option>
          <option value="Nakhon Sawan">นครสวรรค์</option>
          <option value="Uthai Thani">อุทัยธานี</option>
          <option value="Kamphaeng Phet">กำแพงเพชร</option>
          <option value="Tak">ตาก</option>
          <option value="Sukhothai">สุโขทัย</option>
          <option value="Phitsanulok">พิษณุโลก</option>
          <option value="Phichit">พิจิตร</option>
          <option value="Phetchabun">เพชรบูรณ์</option>
          <option value="Ratchaburi">ราชบุรี</option>
          <option value="Kanchanaburi">กาญจนบุรี</option>
          <option value="Suphanburi">สุพรรณบุรี</option>
          <option value="Nakhon Pathom">นครปฐม</option>
          <option value="Samut Sakhon">สมุทรสาคร</option>
          <option value="Samut Songkhram">สมุทรสงคราม</option>
          <option value="Phetchaburi">เพชรบุรี</option>
          <option value="Prachuap Khiri Khan">ประจวบคีรีขันธ์</option>
          <option value="Nakhon Si Thammarat">นครศรีธรรมราช</option>
          <option value="Krabi">กระบี่</option>
          <option value="Phang Nga">พังงา</option>
          <option value="Phuket">ภูเก็ต</option>
          <option value="Surat Thani">สุราษฏณ์ธานี</option>
          <option value="Ranong">ระนอง</option>
          <option value="Chumphon">ชุมพร</option>
          <option value="Satun">สตูล</option>
          <option value="Trang">ตรัง</option>
          <option value="Phatthalung">พัทลุง</option>
          
        </select>
      </div>
      <div class="form-group">
        <label for="contactTime-input"
          >เวลาที่สะดวกให้เจ้าหน้าที่ของเราติดต่อกลับ</label
        >
        <select
          id="copntactTime-input"
          class="form-control"
          v-model="formData.contactTime"
          required
        >
          <option value="-">
            โปรดเลือกเวลาที่สะดวกให้เจ้าหน้าที่ติดต่อกลับ
          </option>
          <option value="08:00 - 11:00">08:00 - 11:00</option>
          <option value="11:00 - 13:00">11:00 - 13:00</option>
          <option value="13:00 - 16:00">13:00 - 16:00</option>
          <option value="17:00 - 20:00">17:00 - 20:00</option>
        </select>
      </div>

      <button type="submit" class="btn btn-danger btn-primary">Submit</button>
    </form>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

export default {
  computed: {
    validCarYear() {
      const currentYear = new Date().getFullYear();
      return (
        this.formData.carYear &&
        this.formData.carYear >= 2000 &&
        this.formData.carYear <= currentYear
      );
    },
  },
  setup() {
    const formData = ref({
      name: "",
      surname: "",
      email: "",
      carYear: "",
      income: "",
      phone: "",
      regist: "",
      carBrand: "",
      otherCarBrand: "",
      province: "",
      contactTime: "",
      submissionDate: "",
    });

    const resetFormData = () => {
      formData.value = {
        name: "",
        surname: "",
        email: "",
        carYear: "",
        income: "",
        phone: "",
        regist: "",
        carBrand: "",
        otherCarBrand: "",
        province: "",
        contactTime: "",
        submissionDate: "",
      };
    };

    const submitForm = async () => {
      try {
        formData.value.submissionDate = new Date().toISOString();
        await addDoc(collection(db, "contactFormEntries"), formData.value);
        alert(
          "ขอบคุณที่สนใจ MoneyMate เจ้าหน้าที่ของเราจะติดต่อกลับไปในไม่ช้า"
        );
        resetFormData();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };

    const isValidEmail = computed(() => {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(String(formData.value.email).toLowerCase());
    });

    const isValidPhone = computed(() => {
      const phoneRegex = /^0\d{8,9}$/;
      return phoneRegex.test(formData.value.phone);
    });

    return { formData, submitForm, isValidEmail, isValidPhone };
  },
};
</script>
